<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400" >
       <!-- <h1>Política de privacidad</h1>
       <div class="title-line"></div> -->
       <div class="textBox" style="white-space: pre-wrap">{{text}}</div>
       
      </div>
    </div>
 
  </div>
</template>

<script>
import importedImg1 from "@/page/chickenfiretown/components/img/4_1.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      text:`Chính sách bảo mật này (sau đây gọi tắt là “Chính sách bảo mật” hay “Chính sách”) được xây dựng và cập nhật bởi CBMALL OPERATION COMPANY LIMITED LIABILITY.
 Phạm vi áp dụng của Chính sách là tất cả các đối tượng sử dụng (sau đây gọi tắt là “Người dùng”), ứng dụng CBMall Lending (sau đây gọi tắt là “Ứng dụng di động”), website www.cbmalldonghelp.com (sau đây gọi tắt là “Website”), không giới hạn ở các hình thức đăng ký sử dụng dịch vụ của CBMall Lending, đăng ký thông tin và đăng ký tài khoản.
 Chính sách bảo mật được xây dựng nhằm cho bạn biết được CBMall Lending sẽ/có thể thu thập, sử dụng, tiết lộ, bảo mật và/hoặc xử lý dữ liệu mà bạn đã cung cấp cho CBMall Lending như thế nào, và chính sách này được xây dựng dựa trên quy định pháp luật Việt Nam liên quan đến bảo mật dữ liệu cá nhân.
 Bằng cách chọn xác nhận vào tiêu mục “Đồng ý điều khoản trong Chính Sách Bảo Mật” khi đăng ký sử dụng, đăng ký thông tin, đăng ký tài khoản trên Ứng dụng di động và/hoặc Website của CBMall Lending, bạn thừa nhận rằng đồng ý toàn bộ các điều khoản và điều kiện quy định tại Chính Sách Bảo Mật này và cho phép CBMall Lending thu thập, sử dụng, tiết lộ và/hoặc xử lý dữ liệu cá nhân của bạn như mô tả trong đây. Trong trường hợp từ chối Chính Sách Bảo Mật, bạn có thể không tải xuống, không đăng ký vào Ứng dụng di động, hoặc không sử dụng các dịch vụ được cung cấp qua Website của CBMall Lending.
 Vui lòng đọc kỹ Chính Sách Bảo Mật này trước khi gửi/cung cấp bất kỳ thông tin nào cho CBMall Lending hoặc khi được CBMall Lending yêu cầu.
CBMall Lending là ứng dụng cho vay tín dụng và vay cá nhân trực tuyến hoàn toàn dành cho tất cả cư dân. Mục tiêu của chúng tôi là cung cấp cho người dùng khoản vay khẩn cấp lên tới 25.000.000 VNĐ.
Với đơn xin vay này, bạn có thể đăng ký vay tiền ở bất cứ đâu, chúng tôi cung cấp các lựa chọn cho các ngân hàng cung cấp khoản vay cho những người báo cáo với Trung tâm Rủi ro, chúng tôi cung cấp các lựa chọn cho vay mà không cần lịch sử tín dụng và không cần cơ quan kiểm tra.
Bạn có thể đăng ký vay thông qua điện thoại di động mà không cần rời khỏi nhà. Nó thoải mái và nhanh chóng, và bạn có thể sử dụng nó bất cứ khi nào bạn cần.
Tỷ lệ phần trăm hàng năm tối đa (APR): 21.9%
Thời hạn vay tối đa: 365 ngày.
Thời hạn vay tối thiểu: 91 ngày.
Thông tin khoản vay:
Số tiền vay: lên tới 25,000,000 VNĐ
Lãi hàng ngày: tối đa 0.06%/ngày
Thuế suất: 8% trên tổng lãi vay
Ví dụ về khoản vay:
Nếu người dùng yêu cầu khoản vay cá nhân với số tiền 10,000 đồng, họ phải trả:
Số tiền vay: 10,000 đồng;
Thời lượng: 180 ngày;
Lãi suất: 10.000*0.06%*180=1080 đồng;
Thuế: 1080*8%=86 đồng
Tổng thanh toán: 11166 VNĐ.
1. THU THẬP DỮ LIỆU CÁ NHÂN
 1.1. Dữ liệu cá nhân nghĩa là và tham chiếu đến thông tin cá nhân gắn với việc xác định danh tính của một người cụ thể (sau đây gọi tắt là “Dữ Liệu Cá Nhân”). Dữ liệu cá nhân mà CBMall Lending sẽ/có thể thu thập bao gồm, nhưng không giới hạn:
Họ và Tên;
Ngày tháng năm sinh;
Số CMND hoặc Hộ chiếu;
Địa chỉ liên hệ;
Địa chỉ thư điện tử;
Danh bạ điện thoại;
Hình ảnh cá nhân;
Hình ảnh, thông tin trên giấy tờ tuỳ thân mà khách hàng cung cấp;
Số điện thoại, số fax;
Tài khoản ngân hàng và thông tin thanh toán;
Thông tin về cấu hình điện thoại, phiên bản app CBMall Lending đang sử dụng;
Thông tin được gửi bởi hoặc liên quan đến (các) thiết bị được sử dụng để truy cập vào Ứng dụng di động hoặc Website;
Dữ liệu tổng hợp khác về nội dung mà sử dụng trên Ứng dụng di động hoặc Website;
 1.2. Chúng tôi sẽ/có thể thu thập Dữ liệu cá nhân về bạn trong các trường hợp:
Khi bạn đăng ký (sử dụng/thông tin/tài khoản) và/hoặc sử dụng Ứng dụng di động hoặc Website của CBMall Lending;
Khi bạn gửi bất kỳ biểu mẫu nào, bao gồm, nhưng không giới hạn ở, đơn đăng ký hoặc các mẫu đơn khác liên quan đến bất kỳ sản phẩm và dịch vụ nào của CBMall Lending, bằng hình thức trực tuyến hay dưới hình thức khác qua Ứng dụng di động hoặc Website;
Tương tác với CBMall Lending qua Ứng dụng di động hoặc Website;
Khi bạn thực hiện các giao dịch thông qua Ứng dụng di động hoặc Website;
 Khi bạn gửi dữ liệu cá nhân của bạn cho CBMall Lending vì bất kỳ lý do gì.
Trên đây chỉ là một số trường hợp phổ biến mà CBMall Lending thu thập dữ liệu cá nhân của bạn, không phản ánh hết toàn bộ các trường hợp mà CBMall Lending sẽ thu thập dữ liệu cá nhân của bạn.
2. MỤC ĐÍCH THU THẬP, SỬ DỤNG, TIẾT LỘ, XỬ LÝ DỮ LIỆU CÁ NHÂN
 Dữ liệu cá nhân về bạn được CBMall Lending thu thập tại mục 1 sẽ/có thể được sử dụng, tiết lộ và/hoặc xử lý cho các mục đích sau đây:
Xác thực thông tin về bạn để đăng ký như một khách hàng của CBMall Lending.
 Chấp thuận quá trình đăng ký của bạn như một khách hàng của CBMall Lending, cung ứng cho bạn một tài khoản đăng nhập vào CBMall Lending cũng như các hoạt động sửa đổi, bổ sung, cập nhật, quản lý Thông tin đăng ký.
 Tiến hành các hoạt động tra cứu và phân tích Người dùng nhằm nâng cao chất lượng dịch vụ, quản lý và bảo vệ các thông tin, hệ thống kỹ thuật dịch vụ, đo lường hiệu suất dịch vụ do CBMall Lending cung ứng và cải thiện sản phẩm, dịch vụ của CBMall Lending.
Thông tin vị trí của bạn: dữ liệu về vị trí địa lý của bạn sẽ được lưu trữ trên máy chủ nhằm giúp bạn sử dụng chức năng tìm kiếm và gợi ý điểm giao dịch gần bạn của Ứng Dụng;
Danh bạ điện thoại: chúng tôi sẽ lưu trữ danh bạ điện thoại của bạn trên máy chủ nhằm phát hiện và ngăn chặn gian lận. Chúng tôi cam kết sẽ tôn trọng và không sử dụng danh bạ điện thoại của bạn vì bất kỳ mục đích nào nếu không có sự đồng ý của bạn;
Máy ảnh:
Để cung cấp dịch vụ tốt hơn, chúng tôi cần sự cho phép của bạn để truy cập vào máy ảnh của bạn. Điều này sẽ được sử dụng để chụp ảnh nhằm mục đích nhận dạng trong quá trình đăng ký khoản vay.
hồ sơ cuộc gọi:
Để hợp lý hóa quy trình đăng ký khoản vay, chúng tôi cần quyền truy cập vào lịch sử cuộc gọi của bạn. Điều này sẽ giúp chúng tôi đánh giá hồ sơ tín dụng của bạn một cách toàn diện hơn, đảm bảo bạn có trải nghiệm vay vốn suôn sẻ hơn.
Quyền nhận dạng khuôn mặt:
Để nâng cao quá trình xác thực, chúng tôi cần quyền truy cập vào máy ảnh của bạn để nhận dạng khuôn mặt. Điều này sẽ đảm bảo rằng tài khoản của bạn được bảo vệ ở mức độ lớn nhất và cung cấp dịch vụ cho vay an toàn hơn.
 Trên cơ sở các thông tin có được, bạn đồng ý rằng, CBMall Lending có thể sử dụng tên của bạn, số điện thoại, địa chỉ liên hệ, email, số fax để cung cấp các thông báo, khảo sát, thông tin sản phẩm, các hoạt động truyền thông, khuyến mại khác tới bạn liên quan đến dịch vụ được cung cấp bởi CBMall Lending.
Chúng tôi không sử dụng bất kỳ biện pháp nào để theo dõi nội dung tin nhắn, trao đổi hoặc hình thức khác nhằm theo dõi người dùng khi sử dụng Ứng Dụng này.
 Các bước xác minh hình ảnh bao gồm:
Xác minh danh tính chủ thể dữ liệu dựa trên giấy tờ tuỳ thân
Xác minh tính xác thực của giấy tờ tuỳ thân
Chuyển đổi dữ liệu hình ảnh trên giấy tờ tuỳ thân thành dạng văn bản thông qua nhận dạng ký tự quang học (OCR)
Nguyên tắc xử lý dữ liệu về quyền riêng tư:
Khi sử dụng Ứng dụng của chúng tôi, chúng tôi cần có sự đồng ý rõ ràng của bạn để chúng tôi thu thập và lưu trữ thông tin của bạn. Chúng tôi cam kết cung cấp trải nghiệm người dùng an toàn để đảm bảo bạn tận hưởng các dịch vụ hiệu quả, mượt mà và được cá nhân hóa. Điều này giúp chúng tôi cung cấp cho bạn trải nghiệm nền tảng riêng tư, an toàn và thuận tiện hơn, đồng thời liên tục cải thiện các dịch vụ và tính năng của chúng tôi.
Chúng tôi bảo vệ tất cả thông tin liên hệ bằng mã hóa và tải thông tin đó lên máy chủ của chúng tôi một cách an toàn. Hãy yên tâm rằng dữ liệu cá nhân được lọc nghiêm ngặt và xóa khỏi máy chủ của chúng tôi sau một khoảng thời gian. Chúng tôi long trọng hứa sẽ không chia sẻ thông tin của bạn với bên thứ ba để đảm bảo rằng quyền riêng tư của bạn được bảo vệ ở mức cao nhất.
 3. LOẠI CHO VAY, THỜI HẠN CHO VAY, PHƯƠNG THỨC CHO VAY, LÃI SUẤT VÀ PHÍ CHO VAY, ĐỒNG TIỀN CHO VAY, TRẢ NỢ
 3.1. Loại cho vay
 Trên cơ sở mục đích, mức độ rủi ro liên quan đến khoản vay cũng như khả năng và thời gian trả nợ của khách hàng, CBMall Lending có thể cho vay ngắn hạn, trung hạn, dài hạn theo thời hạn cụ thể như sau:
Cho vay ngắn hạn là khoản vay có thời hạn vay đến 12 tháng;
Cho vay trung hạn là khoản vay có thời hạn vay từ trên 12 tháng đến 60 tháng;
Cho vay dài hạn là khoản vay có thời hạn vay từ trên 60 tháng.
 3.2. Thời hạn cho vay
 3.2.1. Đối với cho vay kinh doanh
 CBMall Lending và khách hàng căn cứ vào chu kỳ hoạt động kinh doanh, thời hạn thu hồi vốn, khả năng trả nợ của khách hàng, nguồn vốn cho vay và thời hạn hoạt động còn lại của CBMall Lending để thoả thuận về thời hạn cho vay.
 3.2.2. Đối với cho vay tiêu dùng
 CBMall Lending và khách hàng căn cử vào khả năng trả nợ của khách hàng, nguồn vốn cho vay, thời hạn hoạt động còn lại của CBMall Lending để thỏa thuận về thời hạn cho vay.
Thời hạn cho vay tối đa đối với khoản vay tiêu dùng là 48 tháng.
 3.3. Phương thức cho vay.
 CBMall Lending thỏa thuận với khách hàng về việc áp dụng các phương thức cho vay sau đây:
 Cho vay từng lần: Mỗi lần cho vay, Công ty và khách hàng thực hiện thủ tục cho vay và ký kết hợp đồng cho vay. Cho vay từng lần bao gồm nhưng không giới hạn cho vay trả góp.
 Cho vay theo hạn mức: CBMall Lending xác định và thỏa thuận với khách hàng một mức dư nợ cho vay tối đa được duy trì trong một khoảng thời gian nhất định. Trong hạn mức cho vay, CBMall Lending thực hiện cho vay từng lần. Một năm ít nhất một lần, CBMall Lending xem xét xác định lại mức dự nợ cho vay tối đa và thời gian duy trì mức dư nợ này.
 Phương thức khác theo quy định pháp luật và chính sách của CBMall Lending trong từng thời kì
 3.4. Lãi suất và phí cho vay
 3.4.1. Lãi suất cho vay.
 Mức lãi suất cho vay do CBMall Lending và khách hàng thỏa thuận phù hợp với quy định của Ngân Hàng Nhà Nước Việt Nam (sau đây gọi tắt là NHNN) và quy định của CBMall Lending
Lãi suất cho vay tại CBMall Lending từ 21.9%/năm.
 Các sản phẩm cho vay trên ứng dụng có lãi suất cho vay không quá 21.9%/năm, bao gồm cả chi phí và lệ phí, đồng thời không yêu cầu hoàn trả đầy đủ trong vòng 60 ngày trở xuống.
 3.4.2. Phí cho vay
 CBMall Lending và khách hàng thoả thuận về việc thu các khoản phí liên quan đến hoạt động cho vay, gồm:
Phí trả nợ trước hạn trong trường hợp khách hàng trả nợ trước hạn;
Phí trả cho hạn mức tín dụng dự phòng;
 Phí cam kết rút vốn kể từ thời điểm thỏa thuận cho vay có hiệu lực đến ngày giải ngân vốn vay lần đầu;
Các loại phí khác liên quan đến hoạt động cho vay theo quy định của pháp luật.
 3.5. Đồng tiền cho vay, trả nợ
 CBMall Lending thực hiện cho vay bằng đồng Việt Nam phù hợp với quy định tại Quy chế này và quy định của pháp luật có liên quan.
Đồng tiền trả nợ là đồng Việt Nam.
liên hệ chúng tôi
Địa chỉ công ty: Area 2, Bao Yen Commune, Thanh Thuy District, Phu Tho Province
ĐT: +84 0656328371
Email: market@chickenfiretown.com
Trang web chính thức: https://www.chickenfiretown.com`,




    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #F5F7FF;
}
.imgBox1
{
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 4000px;
  background-color: #F5F7FF;
  border-radius: 32px;
  margin: -140px auto 150px;
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
h1{
  /* margin-top: 50px; */
 margin-left: 490px;
 color: #17504C;
 font-size: 40px;
/* border-bottom: 5px solid #04358A; */
display: inline-block;
}
.textBox{
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 18px;
  color: #333333;
}
.imgBox2{
  transform: scale(90%);
  margin: -200px auto 0;
  /* box-shadow:0 8px 8px rgba(0, 0, 0, 0.25); */
}

.title-line{
  width: 430px;
  /* background: url(./components/img/Group1.png); */
  background-repeat: no-repeat;
  background-position: -150px 10px;
  height: 50px;
  /* border: 1px solid red; */
  /* border: 5px solid #1ADD4C; */
  margin: 0 490px;
}
</style>